<template>
    <component 
        :is="widget"
        :organization="organization"/>
    
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

// import CreateRoleDrawer from "./CreateRoleDrawer";
import RoleDetailDrawer from "./RoleDetailDrawer";
import TableActions from './TableActions'
import Members from './Members'



import { mapActions, mapState } from 'vuex';

export default {
    name: 'ThePermissions',
    components: {
        AgGridVue,
        RoleDetailDrawer,
        // CreateRoleDrawer,
        /* eslint-disable */ 
        TableActions,
        Members
         /* eslint-enable */ 

    },
    props: {
        organization: {
            type: Object,
            required: true
        },
    },
    data() {
        return { 
            page: 1,
            pageSize: 15,
            pageSizeOptions: ['15', '30', '50'],
            sort: '',
            count: 0,
            pageName: 'role_list',
            
            columnDefs: [
                {
                    resizable: true,
                    headerName: 'Название',
                    field: 'name',
                    cellStyle: {
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center'
                    },

                },
                {
                    resizable: true,
                    headerName: 'Права',
                    width: 200,

                    autoHeight: true,
                    wrapText: true,
                    cellStyle: {
                        wordBreak: 'normal',
                        padding: '5px 0',
                        lineHeight: '1.4rem',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center'
                    },
                    field: 'permission_types',
                    cellRenderer: params => { 
                        const permissions = params.value
                        for(let i = 1; i < permissions.length; i++) {
                            permissions[i] = permissions[i].toLowerCase()
                        }
                        return permissions.join(', ') 
                    }
                },
                {
                    resizable: true,
                    headerName: 'Пользователи',
                    field: 'users',
                    cellRenderer: 'Members',
                },
                {
                    resizable: true,
                    cellStyle: {
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center'
                    },
                    headerName: '',
                    field: 'actions',
                    cellRenderer: 'TableActions',
                    cellRendererParams: {
                        openEditRoleDrawer: this.openEditRoleDrawer,
                        confirmDeleteRole: this.confirmDeleteRole,
                    },
                    width: 100
                },
                
            ],
            rowData: [],

            columns: [
                {
                    dataIndex: 'name',
                    title: 'Название',
                    key: 'name',
                    scopedSlots: { customRender: 'name' }
                },
                {   
                    width: 100,
                    dataIndex: 'actions',
                    title: '',
                    key: 'actions',
                    scopedSlots: { customRender: 'actions' }
                },
            ],
            tableLoading: false,
            gridApi: null
        }
    },
    computed: {
        ...mapState({
            roles: state => state.organization.roles,
            windowWidth: state => state.windowWidth,
            config: state => state.config.config,
            isMobile: state => state.isMobile
            
        }),
        widget() {
            if(this.isMobile) {
                return () => import('./PermissionList')
            }
            return () => import('./PermissionTable')
        },
        roleList() {
            return this.roles?.[this.organization.id]?.results || []
        },

        roleCount() {
            return this.roles?.[this.organization.id]?.count
        },
        tableSize() {
            return this.config?.theme?.tableSize ? this.config.theme.tableSize : 'small'
        }  
    },
}
</script>

<style>
</style>